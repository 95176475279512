import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.DASHBOARDS.TEXT',
    icon: 'bx-home-circle',
    subItems: [
      {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.LIST.DEFAULT',
        link: '/',
        parentId: 1
      },
      {
        id: 3,
        label: 'MENUITEMS.DASHBOARDS.LIST.SAAS',
        link: '/dashboards/saas',
        parentId: 1
      },
      {
        id: 4,
        label: 'MENUITEMS.DASHBOARDS.LIST.CRYPTO',
        link: '/dashboards/crypto',
        parentId: 1
      },
      {
        id: 5,
        label: 'MENUITEMS.DASHBOARDS.LIST.BLOG',
        link: '/dashboards/blog',
        parentId: 1
      }
    ]
  },
  {
    id: 6,
    label: 'MENUITEMS.UIELEMENTS.TEXT',
    icon: 'bx-tone',
    isUiElement: true,
    subItems: [
      {
        id: 7,
        label: 'MENUITEMS.UIELEMENTS.LIST.ALERTS',
        link: '/ui/alerts',
        parentId: 6
      },
      {
        id: 8,
        label: 'MENUITEMS.UIELEMENTS.LIST.BUTTONS',
        link: '/ui/buttons',
        parentId: 6
      },
      {
        id: 9,
        label: 'MENUITEMS.UIELEMENTS.LIST.CARDS',
        link: '/ui/cards',
        parentId: 6
      },
      {
        id: 10,
        label: 'MENUITEMS.UIELEMENTS.LIST.CAROUSEL',
        link: '/ui/carousel',
        parentId: 6
      },
      {
        id: 11,
        label: 'MENUITEMS.UIELEMENTS.LIST.DROPDOWNS',
        link: '/ui/dropdowns',
        parentId: 6
      },
      {
        id: 12,
        label: 'MENUITEMS.UIELEMENTS.LIST.GRID',
        link: '/ui/grid',
        parentId: 6
      },
      {
        id: 13,
        label: 'MENUITEMS.UIELEMENTS.LIST.IMAGES',
        link: '/ui/images',
        parentId: 6
      },
      {
        id: 14,
        label: 'MENUITEMS.UIELEMENTS.LIST.MODALS',
        link: '/ui/modals',
        parentId: 6
      },
      {
        id: 15,
        label: 'MENUITEMS.UIELEMENTS.LIST.RANGESLIDER',
        link: '/ui/rangeslider',
        parentId: 6
      },
      {
        id: 16,
        label: 'MENUITEMS.UIELEMENTS.LIST.PROGRESSBAR',
        link: '/ui/progressbar',
        parentId: 6
      },
      {
        id: 18,
        label: 'MENUITEMS.UIELEMENTS.LIST.PLACEHOLDER',
        link: '/ui/placeholder',
        parentId: 6
      },
      {
        id: 19,
        label: 'MENUITEMS.UIELEMENTS.LIST.SWEETALERT',
        link: '/ui/sweet-alert',
        parentId: 6
      },
      {
        id: 20,
        label: 'MENUITEMS.UIELEMENTS.LIST.TABS',
        link: '/ui/tabs-accordions',
        parentId: 6
      },
      {
        id: 21,
        label: 'MENUITEMS.UIELEMENTS.LIST.TYPOGRAPHY',
        link: '/ui/typography',
        parentId: 6
      },
      {
        id: 22,
        label: 'MENUITEMS.UIELEMENTS.LIST.VIDEO',
        link: '/ui/video',
        parentId: 6
      },
      {
        id: 23,
        label: 'MENUITEMS.UIELEMENTS.LIST.GENERAL',
        link: '/ui/general',
        parentId: 6
      },
      {
        id: 24,
        label: 'MENUITEMS.UIELEMENTS.LIST.COLORS',
        link: '/ui/colors',
        parentId: 6
      },
      {
        id: 25,
        label: 'MENUITEMS.UIELEMENTS.LIST.CROPPER',
        link: '/ui/image-crop',
        parentId: 6
      }
    ]
  },
  {
    id: 26,
    label: 'MENUITEMS.APPS.TEXT',
    icon: 'bx-customize',
    subItems: [
      {
        id: 27,
        label: 'MENUITEMS.CALENDAR.TEXT',
        link: '/calendar',
        parentId: 26
      },
      {
        id: 28,
        label: 'MENUITEMS.CHAT.TEXT',
        link: '/chat',
        parentId: 26
      },
      {
        id: 29,
        label: 'MENUITEMS.FILEMANAGER.TEXT',
        link: '/filemanager',
        parentId: 26
      },
      {
        id: 30,
        label: 'MENUITEMS.EMAIL.TEXT',
        subItems: [
          {
            id: 31,
            label: 'MENUITEMS.EMAIL.LIST.INBOX',
            link: '/email/inbox',
            parentId: 30
          },
          {
            id: 32,
            label: 'MENUITEMS.EMAIL.LIST.READEMAIL',
            link: '/email/read',
            parentId: 30
          },
          {
            id: 33,
            label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.TEXT',
            parentId: 30,
            subItems: [
              {
                id:34 ,
                label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.LIST.BASIC',
                link: '/email/basic',
                parentId:33
              },
              {
                id:35 ,
                label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.LIST.ALERT',
                link: '/email/alert',
                parentId:33
              },
              {
                id:36 ,
                label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.LIST.BILLING',
                link: '/email/billing',
                parentId:33
              }
            ]
          }
        ]
      },
      {
        id: 37,
        label: 'MENUITEMS.ECOMMERCE.TEXT',
        subItems: [
          {
            id: 38,
            label: 'MENUITEMS.ECOMMERCE.LIST.PRODUCTS',
            link: '/ecommerce/products',
            parentId: 37
          },
          {
            id: 39,
            label: 'MENUITEMS.ECOMMERCE.LIST.PRODUCTDETAIL',
            link: '/ecommerce/product-detail/1',
            parentId: 37
          },
          {
            id: 40,
            label: 'MENUITEMS.ECOMMERCE.LIST.ORDERS',
            link: '/ecommerce/orders',
            parentId: 37
          },
          {
            id: 41,
            label: 'MENUITEMS.ECOMMERCE.LIST.CUSTOMERS',
            link: '/ecommerce/customers',
            parentId: 37
          },
          {
            id: 42,
            label: 'MENUITEMS.ECOMMERCE.LIST.CART',
            link: '/ecommerce/cart',
            parentId: 37
          },
          {
            id: 43,
            label: 'MENUITEMS.ECOMMERCE.LIST.CHECKOUT',
            link: '/ecommerce/checkout',
            parentId: 37
          },
          {
            id: 44,
            label: 'MENUITEMS.ECOMMERCE.LIST.SHOPS',
            link: '/ecommerce/shops',
            parentId: 37
          },
          {
            id: 45,
            label: 'MENUITEMS.ECOMMERCE.LIST.ADDPRODUCT',
            link: '/ecommerce/add-product',
            parentId: 37
          }
        ]
      },
      {
        id: 46,
        label: 'MENUITEMS.CRYPTO.TEXT',
        icon: 'bx-bitcoin',
        subItems: [
          {
            id: 47,
            label: 'MENUITEMS.CRYPTO.LIST.WALLET',
            link: '/crypto/wallet',
            parentId: 46
          },
          {
            id: 48,
            label: 'MENUITEMS.CRYPTO.LIST.BUY/SELL',
            link: '/crypto/buy-sell',
            parentId: 46
          },
          {
            id: 49,
            label: 'MENUITEMS.CRYPTO.LIST.EXCHANGE',
            link: '/crypto/exchange',
            parentId: 46
          },
          {
            id: 50,
            label: 'MENUITEMS.CRYPTO.LIST.LENDING',
            link: '/crypto/lending',
            parentId: 46
          },
          {
            id: 51,
            label: 'MENUITEMS.CRYPTO.LIST.ORDERS',
            link: '/crypto/orders',
            parentId: 46
          },
          {
            id: 52,
            label: 'MENUITEMS.CRYPTO.LIST.KYCAPPLICATION',
            link: '/crypto/kyc-application',
            parentId: 46
          },
          {
            id: 53,
            label: 'MENUITEMS.CRYPTO.LIST.ICOLANDING',
            link: '/crypto-ico-landing',
            parentId: 46
          }
        ]
      },
      {
        id: 54,
        label: 'MENUITEMS.PROJECTS.TEXT',
        subItems: [
          {
            id: 55,
            label: 'MENUITEMS.PROJECTS.LIST.GRID',
            link: '/projects/grid',
            parentId: 54
          },
          {
            id: 56,
            label: 'MENUITEMS.PROJECTS.LIST.PROJECTLIST',
            link: '/projects/list',
            parentId: 54
          },
          {
            id: 57,
            label: 'MENUITEMS.PROJECTS.LIST.OVERVIEW',
            link: '/projects/overview',
            parentId: 54
          },
          {
            id: 58,
            label: 'MENUITEMS.PROJECTS.LIST.CREATE',
            link: '/projects/create',
            parentId: 54
          }
        ]
      },
      {
        id: 59,
        label: 'MENUITEMS.TASKS.TEXT',
        subItems: [
          {
            id: 60,
            label: 'MENUITEMS.TASKS.LIST.TASKLIST',
            link: '/tasks/list',
            parentId: 59
          },
          {
            id: 61,
            label: 'MENUITEMS.TASKS.LIST.KANBAN',
            link: '/tasks/kanban',
            parentId: 59
          },
          {
            id: 62,
            label: 'MENUITEMS.TASKS.LIST.CREATETASK',
            link: '/tasks/create',
            parentId: 59
          }
        ]
      },
      {
        id: 63,
        label: 'MENUITEMS.CONTACTS.TEXT',
        icon: 'bxs-user-detail',
        subItems: [
          {
            id: 64,
            label: 'MENUITEMS.CONTACTS.LIST.USERGRID',
            link: '/contacts/grid',
            parentId: 63
          },
          {
            id: 65,
            label: 'MENUITEMS.CONTACTS.LIST.USERLIST',
            link: '/contacts/list',
            parentId: 63
          },
          {
            id: 66,
            label: 'MENUITEMS.CONTACTS.LIST.PROFILE',
            link: '/contacts/profile',
            parentId: 63
          }
        ]
      },
      {
        id: 67,
        label: 'MENUITEMS.BLOG.TEXT',
        subItems: [
          {
            id: 68,
            label: 'MENUITEMS.BLOG.LIST.BLOGLIST',
            link: '/blog/list',
            parentId: 67
          },
          {
            id: 69,
            label: 'MENUITEMS.BLOG.LIST.BLOGGRID',
            link: '/blog/grid',
            parentId: 67
          },
          {
            id: 70,
            label: 'MENUITEMS.BLOG.LIST.DETAIL',
            link: '/blog/detail',
            parentId: 67
          }
        ]
      }
    ]
  },
  {
    id: 71,
    icon: 'bx-collection',
    label: 'MENUITEMS.COMPONENTS.TEXT',
    subItems: [
      {
        id: 72,
        label: 'MENUITEMS.FORMS.TEXT',
        subItems: [
          {
            id: 73,
            label: 'MENUITEMS.FORMS.LIST.ELEMENTS',
            link: '/form/elements',
            parentId: 72
          },
          {
            id: 74,
            label: 'MENUITEMS.FORMS.LIST.VALIDATION',
            link: '/form/validation',
            parentId: 72
          },
          {
            id: 75,
            label: 'MENUITEMS.FORMS.LIST.ADVANCED',
            link: '/form/advanced',
            parentId: 72
          },
          {
            id: 76,
            label: 'MENUITEMS.FORMS.LIST.EDITOR',
            link: '/form/editor',
            parentId: 72
          },
          {
            id: 77,
            label: 'MENUITEMS.FORMS.LIST.FILEUPLOAD',
            link: '/form/uploads',
            parentId: 72
          },
          {
            id: 78,
            label: 'MENUITEMS.FORMS.LIST.REPEATER',
            link: '/form/repeater',
            parentId: 72
          },
          {
            id: 79,
            label: 'MENUITEMS.FORMS.LIST.WIZARD',
            link: '/form/wizard',
            parentId: 72
          },
          {
            id: 80,
            label: 'MENUITEMS.FORMS.LIST.MASK',
            link: '/form/mask',
            parentId: 72
          }
        ]
      },
      {
        id: 81,
        label: 'MENUITEMS.TABLES.TEXT',
        subItems: [
          {
            id: 82,
            label: 'MENUITEMS.TABLES.LIST.BASIC',
            link: '/tables/basic',
            parentId: 81
          },
          {
            id: 83,
            label: 'MENUITEMS.TABLES.LIST.ADVANCED',
            link: '/tables/advanced',
            parentId: 81
          }
        ]
      },
      {
        id: 84,
        label: 'MENUITEMS.CHARTS.TEXT',
        subItems: [
          {
            id: 85,
            label: 'MENUITEMS.CHARTS.LIST.APEX',
            link: '/charts/apex',
            parentId: 84
          },
          {
            id: 86,
            label: 'MENUITEMS.CHARTS.LIST.CHARTJS',
            link: '/charts/chartjs',
            parentId: 84
          },
          {
            id: 87,
            label: 'MENUITEMS.CHARTS.LIST.CHARTIST',
            link: '/charts/chartist',
            parentId: 84
          },
          {
            id: 88,
            label: 'MENUITEMS.CHARTS.LIST.ECHART',
            link: '/charts/echart',
            parentId: 84
          }
        ]
      },
      {
        id: 89,
        label: 'MENUITEMS.ICONS.TEXT',
        subItems: [
          {
            id: 90,
            label: 'MENUITEMS.ICONS.LIST.BOXICONS',
            link: '/icons/boxicons',
            parentId: 89
          },
          {
            id: 91,
            label: 'MENUITEMS.ICONS.LIST.MATERIALDESIGN',
            link: '/icons/materialdesign',
            parentId: 89
          },
          {
            id: 92,
            label: 'MENUITEMS.ICONS.LIST.DRIPICONS',
            link: '/icons/dripicons',
            parentId: 89
          },
          {
            id: 93,
            label: 'MENUITEMS.ICONS.LIST.FONTAWESOME',
            link: '/icons/fontawesome',
            parentId: 89
          }
        ]
      },
      {
        id: 94,
        label: 'MENUITEMS.MAPS.TEXT',
        subItems: [
          {
            id: 95,
            label: 'MENUITEMS.MAPS.LIST.GOOGLEMAP',
            link: '/maps/google',
            parentId: 94
          }
        ]
      }
    ]
  },
  {
    id: 96,
    label: 'HEADER.EXTRA_PAGES.TITLE',
    icon: 'bx-file',
    subItems: [
      {
        id: 97,
        label: 'MENUITEMS.INVOICES.TEXT',
        subItems: [
          {
            id: 98,
            label: 'MENUITEMS.INVOICES.LIST.INVOICELIST',
            link: '/invoices/list',
            parentId: 97
          },
          {
            id: 99,
            label: 'MENUITEMS.INVOICES.LIST.INVOICEDETAIL',
            link: '/invoices/detail',
            parentId: 97
          }
        ]
      },
      {
        id: 100,
        label: 'MENUITEMS.AUTHENTICATION.TEXT',
        subItems: [
          {
            id: 101,
            label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN',
            link: '/pages/login-1',
            parentId: 100
          },
          {
            id: 102,
            label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN2',
            link: '/pages/login-2',
            parentId: 100
          },
          {
            id: 103,
            label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER',
            link: '/pages/register-1',
            parentId: 100
          },
          {
            id: 103,
            label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER2',
            link: '/pages/register-2',
            parentId: 100
          },
          {
            id: 104,
            label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD',
            link: '/pages/recoverpwd-1',
            parentId: 100
          },
          {
            id: 105,
            label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD2',
            link: '/pages/recoverpwd-2',
            parentId: 100
          },
          {
            id: 106,
            label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN',
            link: '/pages/lock-screen-1',
            parentId: 100
          },
          {
            id: 107,
            label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN2',
            link: '/pages/lock-screen-2',
            parentId: 100
          },
          {
            id: 108,
            label: 'MENUITEMS.AUTHENTICATION.LIST.CONFIRMMAIL',
            link: '/pages/confirm-mail',
            parentId: 100
          },
          {
            id: 109,
            label: 'MENUITEMS.AUTHENTICATION.LIST.CONFIRMMAIL2',
            link: '/pages/confirm-mail-2',
            parentId: 100
          },
          {
            id: 110,
            label: 'MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION',
            link: '/pages/email-verification',
            parentId: 100
          },
          {
            id: 111,
            label: 'MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION2',
            link: '/pages/email-verification-2',
            parentId: 100
          },
          {
            id: 112,
            label: 'MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION',
            link: '/pages/two-step-verification',
            parentId: 100
          },
          {
            id: 113,
            label: 'MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION2',
            link: '/pages/two-step-verification-2',
            parentId: 100
          }
        ]
      },
      {
        id: 114,
        label: 'MENUITEMS.UTILITY.TEXT',
        icon: 'bx-file',
        subItems: [
          {
            id: 115,
            label: 'MENUITEMS.UTILITY.LIST.STARTER',
            link: '/pages/starter',
            parentId: 114
          },
          {
            id: 116,
            label: 'MENUITEMS.UTILITY.LIST.MAINTENANCE',
            link: '/pages/maintenance',
            parentId: 114
          },
          {
            id: 117,
            label: 'Coming Soon',
            link: '/pages/coming-soon',
            parentId: 114
          },
          {
            id: 118,
            label: 'MENUITEMS.UTILITY.LIST.TIMELINE',
            link: '/pages/timeline',
            parentId: 114
          },
          {
            id: 119,
            label: 'MENUITEMS.UTILITY.LIST.FAQS',
            link: '/pages/faqs',
            parentId: 114
          },
          {
            id: 120,
            label: 'MENUITEMS.UTILITY.LIST.PRICING',
            link: '/pages/pricing',
            parentId: 114
          },
          {
            id: 121,
            label: 'MENUITEMS.UTILITY.LIST.ERROR404',
            link: '/pages/404',
            parentId: 114
          },
          {
            id: 122,
            label: 'MENUITEMS.UTILITY.LIST.ERROR500',
            link: '/pages/500',
            parentId: 114
          }
        ]
      }
    ]
  }
];

