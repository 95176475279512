/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import Swal, { SweetAlertResult } from 'sweetalert2';

import { GlobalState } from '../models/global-state.model';
import { GlobalStateService } from './global-state.service';
import { ProjectsService } from './projects.service';
import { Observable } from 'rxjs';
import { BrowserStorageService } from './browser-storage.service';
import { DataPlotService } from './data-plot.service';
import { ProcessesService } from './processes.service';
import { SendEmailService } from './send-email.service';
import { RegisterService } from './register.service';

const ROUTES = {
  home: '/',
  design: '/design',
  offTargets: '/off-targets',
  selectMode: '/select-mode',
  mainTargetsPlot: '/main-targets-plot',
  offTargetsPlot: '/off-targets-plot',
  reference: '/reference'
};

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public globalState: GlobalState;
  public user: string;

  private swalInstance: Promise<SweetAlertResult<any> | void> | null = null;
  private shouldRedirect: boolean = false;

  constructor(
    private router: Router,
    private globalStateService: GlobalStateService,
    private projectsService: ProjectsService,
    private browserStorageService: BrowserStorageService,
    private dataPlotService: DataPlotService,
    private processesService: ProcessesService,
    private sendEmailService: SendEmailService,
    private registerService: RegisterService,
  ) {
    this.globalStateService.getGlobalState().subscribe((globalState) => {
      this.globalState = globalState;
    });
  }

  public showModalValidateDataQuery() {
    Swal.fire({
      icon: 'success',
      title: 'Data sent and validated correctly',
      showConfirmButton: true,
      confirmButtonText: 'Accept'
    });
  }

  public showModalSaveRegions() {
    Swal.fire({
      icon: 'success',
      title: 'Save regions successfully',
      showConfirmButton: true,
      confirmButtonText: 'Accept'
    });
  }

  public showModalRemoveProject(onConfirm: ()=> void): void {
    Swal.fire({
      title: 'You are sure?',
      text: 'Are you sure you want to delete this project? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete',
      cancelButtonText: 'No, cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
        this.showModalSuccess();
      }
    });
  }

  public showModalSuccess(): void {
    Swal.fire({
      title: 'Deleted Project!',
      text: 'The project has been successfully deleted.',
      icon: 'success',
      timer: 4000,
      timerProgressBar: true,
      showConfirmButton: false
    });
  }

  public showModalErrorResetPassword() {
    Swal.fire({
      icon: 'error',
      title: 'Error resetting password',
      showConfirmButton: true,
      confirmButtonText: 'Accept'
    });
  }

  public showModalResetPassword() {
    Swal.fire({
      icon: 'success',
      title: 'Password reset successfully',
      showConfirmButton: true,
      confirmButtonText: 'Accept'
    });
  }

  public showModalErrorRecoveryPassword() {
    Swal.fire({
      icon: 'error',
      title: 'Error recovering password',
      showConfirmButton: true,
      confirmButtonText: 'Accept'
    });
  }

  public showModalRecoveryPassword() {
    Swal.fire({
      icon: 'success',
      title: 'Email sent successfully',
      showConfirmButton: true,
      confirmButtonText: 'Accept'
    });
  }

  public showModalNewUserRegistered() {
    Swal.fire({
      icon: 'success',
      title: 'New user registered',
      showConfirmButton: true,
      confirmButtonText: 'Accept'
    });
  }

  public showModaUserLogin() {
    Swal.fire({
      icon: 'success',
      title: 'User logged in successfully',
      showConfirmButton: true,
      confirmButtonText: 'Accept'
    });
  }

  public showModalErrorRegisteredUser() {
    Swal.fire({
      icon: 'error',
      title: 'Error registering user',
      showConfirmButton: true,
      confirmButtonText: 'Accept'
    });
  }

  public showModalActivatedUser() {
    Swal.fire({
      icon: 'success',
      title: 'User activated successfully',
      showConfirmButton: true,
      confirmButtonText: 'Accept'
    });
  }

  public showModalErrorActivatedUser() {
    Swal.fire({
      icon: 'error',
      title: 'Error activating user',
      showConfirmButton: true,
      confirmButtonText: 'Accept'
    });
  }

  public showModalErrorLoginUser() {
    Swal.fire({
      icon: 'error',
      title: 'Error Login user',
      showConfirmButton: true,
      confirmButtonText: 'Accept'
    });
  }

  public showModalErrorPasswordUser() {
    Swal.fire({
      icon: 'error',
      title: 'Error input password user',
      html: `
        Password must contain at least:
          <br />
          An uppercase letter.
          <br />
          A lowercase letter.
          <br />
          A number.
          <br />
          A special character.
      `,
      showConfirmButton: true,
      confirmButtonText: 'Accept'
    });
  }

  public showModalErrorResponse() {
    Swal.fire({
      icon: 'error',
      title:
      'Invalid response Plot graphics! When you click accept you will be redirected to the home.',
      showConfirmButton: true,
      confirmButtonText: 'Accept',
      showCancelButton: true,
      cancelButtonText: 'Continue'
    }).then((result) => {
      if (result.isConfirmed) {
        this.showModalGoToHome();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.globalStateService.setGlobalState({ taskID: '' });
      }
    });
  }

  public showModalWhenHardReload() {
    Swal.fire({
      icon: 'error',
      title: 'The saved processes will be lost, are you sure?',
      text: 'You will be redirected to the home page.',
      showConfirmButton: true,
      confirmButtonText: 'Take me home',
      showCancelButton: true,
      cancelButtonText: 'Continue here'
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.globalState.taskID !== '') {
          this.processesService.cancelTask().subscribe(() => {
            this.globalStateService.setGlobalState({
              taskID: ''
            });
            this.showModalGoToHome();
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.browserStorageService.setSessionStorageItem('isReload', 'false');

        this.globalStateService.setGlobalState({ taskID: '' });
      } else if (
        result.dismiss === Swal.DismissReason.backdrop ||
        result.dismiss === Swal.DismissReason.esc
      ) {
        this.browserStorageService.setSessionStorageItem('isReload', 'false');
      }
    });
  }

  public showModalGoToHome() {
    Swal.fire({
      icon: 'warning',
      html: '<h5 class="swal-text">You will be redirected to the home page.</h5>',
      timer: 4000,
      timerProgressBar: true,
      showConfirmButton: false,
      customClass: {
        popup: 'custom-popup',
        title: 'swal-title',
        htmlContainer: 'swal-text'
      },
      willClose: () => {
        this.router.navigate([ROUTES.home]);
      }
    });
  }

  public showModalKeepQueryFileData() {
    Swal.fire({
      icon: 'warning',
      title: 'Do you want to keep the current Query file data?',
      showConfirmButton: true,
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.globalStateService.setGlobalState({
          requestedToAddNewQueryFile: false
        });

        this.router.navigate([ROUTES.selectMode]);
      } else if (result.isDismissed) {
        this.globalStateService.setGlobalState({
          requestedToAddNewQueryFile: true
        });

        this.router.navigate([ROUTES.selectMode]);
      }
    });
  }

  public showModalCreateNewReference() {
    Swal.fire({
      icon: 'success',
      title: 'Data sent and create new reference correctly',
      showConfirmButton: true,
      confirmButtonText: 'Accept'
    });
  }

  public showModalAddAnnotationReference() {
    Swal.fire({
      icon: 'success',
      title: 'Data sent and add new annotation reference correctly',
      showConfirmButton: true,
      confirmButtonText: 'Accept'
    });
  }

  public showModalDesignKeepReference(callback?: ()=> void) {
    Swal.fire({
      icon: 'warning',
      title: 'Keep the same reference?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate([ROUTES.mainTargetsPlot]);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.globalStateService.setGlobalState({
          currentRoutePath: ROUTES.design
        });

        this.router.navigate(['reference']);
      }
    });
  }

  public showModalOffTargetsKeepReference() {
    this.shouldRedirect = false;

    Swal.fire({
      icon: 'warning',
      title: 'Keep the same reference?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.globalStateService.getGlobalState().subscribe((globalState) => {
          if (!this.shouldRedirect) {
            this.shouldRedirect = true;

            this.dataPlotService
              .fetchAndSetOffTargetsPlotData(globalState)
              .subscribe(
                (data) => {
                  if (data.htmlFileNames && this.shouldRedirect) {
                    this.router.navigate([ROUTES.offTargetsPlot]);
                  }
                },
                () => {
                  this.showModalErrorResponse();
                }
              );
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.shouldRedirect = true;
        this.router.navigate([ROUTES.reference]);
      }
    });
  }

  public showModalCancelTask(): void {
    this.swalInstance = Swal.fire({
      icon: 'info',
      title: 'This process might take a while.',
      text: 'We will send you an email when it is done.',
      showConfirmButton: true,
      confirmButtonText: 'Ok, let me know',
      showCancelButton: true,
      cancelButtonText: 'Cancel task'
    }).then((result) => {
      if (result.isConfirmed) {
        const taskID = this.globalState.taskID;
        const user = this.registerService.currentUserValue?.email;
        this.user = user;
        this.sendEmailService.sendEmail(user, taskID).subscribe(() => {
          Swal.fire('Ok, we will let you know.');
        });
      } else if (result.isDismissed) {
        this.processesService.cancelTask().subscribe(() => {
          Swal.fire('Task canceled.');

          this.globalStateService.setGlobalState({
            taskID: ''
          });
        });
      }
    });
  }

  public closeModal(): void {
    if (this.swalInstance) {
      Swal.close();
      this.swalInstance = null;
    }
  }

}
