import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EndpointsService {
  public readonly apoloApiBaseUrl = environment.apoloApiBaseUrl;
  public readonly apoloApiUser = environment.apiUsersUrl;

  public endpoints = {
    // * GET
    getAllProjectsInformation: `${ this.apoloApiBaseUrl }${ environment.getAllProjectsInformation }`,
    getAllReferencesInformation: `${ this.apoloApiBaseUrl }${ environment.getAllReferencesInformation }`,
    getAllQueriesInformation: `${ this.apoloApiBaseUrl }${ environment.getAllQueriesInformation }`,
    // ! Endpoints that require an /{id} parameter ~ see below
    getProjectInformation: `${ this.apoloApiBaseUrl }${ environment.getProjectInformation }`,
    getReferenceInformation: `${ this.apoloApiBaseUrl }${ environment.getReferenceInformation }`,
    getQueryInformation: `${ this.apoloApiBaseUrl }${ environment.getQueryInformation }`,
    getProjectComparisons: `${ this.apoloApiBaseUrl }${ environment.getProjectComparisons }`,
    getQueryProjects: `${ this.apoloApiBaseUrl }${ environment.getQueryProjects }`,
    getReferenceProjects: `${ this.apoloApiBaseUrl }${ environment.getReferenceProjects }`,
    getProjectReport: `${ this.apoloApiBaseUrl }${ environment.getProjectReport }`,
    getTargetsTable: `${ this.apoloApiBaseUrl }${ environment.getTargetsTable }`,
    getMaintargetsPlotFromDB: `${ this.apoloApiBaseUrl }${ environment.getMaintargetsPlotFromDB }`,
    getOfftargetsPlotFromDB: `${ this.apoloApiBaseUrl }${ environment.getOfftargetsPlotFromDB }`,
    getEfficiencyPlotFromDB: `${ this.apoloApiBaseUrl }${ environment.getEfficiencyPlotFromDB }`,

    // * POST
    // ! Endpoints that require Body ~ see below
    saveSelectedRegion: `${ this.apoloApiBaseUrl }${ environment.saveSelectedRegion }`,
    getChimeraSequenceFile: `${ this.apoloApiBaseUrl }${ environment.getChimeraSequenceFile }`,
    createNewProject: `${ this.apoloApiBaseUrl }${ environment.createNewProject }`,
    createNewReference: `${ this.apoloApiBaseUrl }${ environment.createNewReference }`,
    validateQueryDataFile: `${ this.apoloApiBaseUrl }${ environment.validateQueryDataFile }`,
    validateQueryDataTextBox: `${ this.apoloApiBaseUrl }${ environment.validateQueryDataTextBox }`,
    getMaintargetsPlot: `${ this.apoloApiBaseUrl }${ environment.getMaintargetsPlot }`,
    getEfficiencyPlot: `${ this.apoloApiBaseUrl }${ environment.getEfficiencyPlot }`,
    getOfftargetsPlot: `${ this.apoloApiBaseUrl }${ environment.getOfftargetsPlot }`,
    getOfftargetsPlotFromQuery: `${ this.apoloApiBaseUrl }${ environment.getOfftargetsPlotFromQuery }`,
    sendFinishEmail: `${ this.apoloApiBaseUrl }${ environment.sendFinishEmail }`,
    // ! Endpoints that require an /{id} parameter ~ see below
    removeProject: `${ this.apoloApiBaseUrl }${ environment.removeProject }`,
    removeOffTargetComparison: `${ this.apoloApiBaseUrl }${ environment.removeOffTargetComparison }`,
    cancelTask: `${ this.apoloApiBaseUrl }${ environment.cancelTask }`,

    // * PUT
    addNewAnnotationToReference: `${ this.apoloApiBaseUrl }${ environment.addNewAnnotationToReference }`
  };
}
