import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { ModalService } from './services/modal.service';
import { BrowserStorageService } from './services/browser-storage.service';
import { ProcessesService } from './services/processes.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private isReloadKey: string = 'isReload';
  private hasCheckedReload: boolean = false;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private browserStorageService: BrowserStorageService,
    private processesService: ProcessesService
  ) {}

  public ngOnInit() {
    this.checkForReload();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .subscribe((event: NavigationEnd) => {
        this.checkForReload();
        this.browserStorageService.removeSessionStorageItem(this.isReloadKey);
      });
  }

  private checkForReload() {
    if (!this.hasCheckedReload) {
      const isReload = JSON.parse(
        this.browserStorageService.getSessionStorageItem(this.isReloadKey) ||
          'false'
      );

      if (
        isReload &&
        window.location.pathname !== '/' &&
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/register' &&
        window.location.pathname !== '/active-user' &&
        window.location.pathname !== '/recover-password' &&
        window.location.pathname !== '/reset-password' &&
        window.location.pathname !== '/users' &&
        window.location.pathname !== '/users/create'
      ) {
        this.modalService.showModalWhenHardReload();
        this.browserStorageService.removeSessionStorageItem(this.isReloadKey);
      }

      this.hasCheckedReload = true;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public unloadNotification(event: BeforeUnloadEvent): any {
    this.browserStorageService.setSessionStorageItem(this.isReloadKey, 'true');

    event.preventDefault();

    // * Fallback for older browsers...
    // * Note: The below line deprecated but may still work in some browsers.
    // * Most modern browsers do not display the custom message and show their own generic message.
    const message =
      'Are you sure you want to leave? Your changes may not be saved.';
    event.returnValue = message;
  }
}
