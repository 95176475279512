import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { Page404Component } from './pages/page404/page404.component';
import { AdminGuard } from './core/guards/admin.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule)
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then((m) => m.RegisterModule)
  },
  {
    path: 'active-user',
    loadChildren: () =>
      import('./pages/active-user/active-user.module').then((m) => m.ActiveUserModule)
  },
  {
    path: 'select-mode',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import('./pages/design-off-targets/design-off-targets.module').then(
        (m) => m.DesignOffTargetsModule
      )
  },
  {
    path: 'design',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import('./pages/design/design.module').then((m) => m.DesignModule)
  },
  {
    path: 'off-targets',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import('./pages/off-targets/off-targets.module').then(
        (m) => m.OffTargetsModule
      )
  },
  {
    path: 'reference',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import('./pages/reference/reference.module').then(
        (m) => m.ReferenceModule
      )
  },
  {
    path: 'main-targets-plot',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import('./pages/main-target-plot/main-targets-plot.module').then(
        (m) => m.MainTargetsPlotModule
      )
  },
  {
    path: 'off-targets-plot',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import('./pages/off-targets-plot/off-targets-plot.module').then(
        (m) => m.OffTargetsPlotModule
      )
  },
  {
    path: 'efficiency-plot',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import('./pages/effiencicy-plot/efficiency.plot.module').then(
        (m) => m.EfficiencyPlotModule
      )
  },

  {
    path: 'users',
    canActivate: [AuthGuard, AdminGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import('./pages/users/users.module').then((m) => m.UsersModule)
  },
  {
    path: 'users/create',
    canActivate: [AuthGuard, AdminGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import('./pages/users-form/users-form.module').then(
        (m) => m.UsersFormModule
      )
  },
  {
    path: 'users/:id',
    canActivate: [AuthGuard, AdminGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import('./pages/users-detail/users-detail.module').then(
        (m) => m.UsersDetailModule
      )
  },
  {
    path: 'users/:id/edit',
    canActivate: [AuthGuard, AdminGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import('./pages/users-form/users-form.module').then(
        (m) => m.UsersFormModule
      )
  },
  {
    path: 'recover-password',
    loadChildren: () =>
      import('./pages/recovery-password/recovery-password.module').then(
        (m) => m.RecoveryPasswordModule
      )
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      )
  },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
