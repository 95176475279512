// export layout related constants
/* eslint-disable */
export const LAYOUT_VERTICAL = 'vertical';

export const LAYOUT_HORIZONTAL = 'horizontal';

export const LAYOUT_WIDTH = 'fluid';

export const SIDEBAR_TYPE = 'dark';

export const TOPBAR = 'dark';
/* eslint-enable */
