import * as queryString from 'query-string';

export enum FilterOperation {
  EQ = 'eq',
  NEQ = 'neq',
  GT = 'gt',
  GTE = 'gte',
  LT = 'lt',
  LTE = 'lte',
  CONTAINS = 'cont',
  NOT_CONTAINS = 'n_cont',
  IN = 'in',
  IN_LITERAL = 'in_literal',
}
export class Filter {
  public page: number;
  public pageSize: number;
  public totalItems: number;
  public query: string;
  public params: any;
  public orderBy: string[];

  constructor(
    page = 1,
    pageSize = 30,
    totalItems = 0,
    query = null,
    params = {},
    orderBy = ['-id']
  ) {
    this.page = page;
    this.pageSize = pageSize;
    this.totalItems = totalItems;
    this.query = query;
    this.params = params;
    this.orderBy = orderBy;
  }

  toQueryString(): string {
    // Asegurarse de que los valores sean cadenas
    return Object.entries(this.params)
      .map(([key, value]) => `${ encodeURIComponent(key) }=${ encodeURIComponent(String(value)) }`)
      .join('&');
  }

  fromQueryString( query: string ): void {
    const parsed = queryString.parse( query );

    if ( parsed.orderBy !== undefined ) {
      ( parsed.orderBy as Array<string> ).forEach( (orderBy) => {
        this.orderBy.push( orderBy );
      } );
    }

    if ( parsed.params !== undefined ) {
      this.params = parsed.orderBy as Record<string, any>;
    }

    if ( parsed.params !== undefined ) {
      this.query = parsed.params as string;
    }

    if ( parsed.pageSize !== undefined ) {
      this.pageSize = parseInt( parsed.pageSize as string );
    }

    if ( parsed.totalItems !== undefined ) {
      this.totalItems = parseInt( parsed.totalItems as string );
    }

    if ( parsed.page !== undefined ) {
      this.page = parseInt( parsed.page as string );
    }

    this.params = {}; // Reinicia params antes de asignar nuevos valores
    for (const key in parsed) {
    // eslint-disable-next-line no-prototype-builtins
      if (parsed.hasOwnProperty(key) && key !== 'page' && key !== 'pagesize' && key !== 'sort' && key !== 'totalItems') {
        this.params[key] = parsed[key];
      }
    }
  }

  addFilter(field: string, operation: FilterOperation, value: any): void {
    this.removeFilter(field, operation);
    if (!this.params[operation]) {
      this.params[operation] = [];
    }
    this.params[operation].push(`${ field }:${ value }`);
  }

  removeFilter( field: string, operation: FilterOperation ): void {
    if ( !this.params[operation] ) {
      return;
    }
    this.params[operation] = this.params[operation].filter( (value) => {
      return !value.includes( field + ':' );
    } );
    if ( this.params[operation].length == 0 ) {
      delete this.params[operation];
    }
  }
}
