export const environment = {
  production: false,
  apiUrl: 'https://apolorocket.com',
  apiUsersUrl: 'https://apolorocket.com/api-users/',
  // ! Current working API URL below.
  apoloApiBaseUrl: 'https://apolorocket.com/api/',

  // * GET
  getAllProjectsInformation: 'getAllProjectsInformation/',
  getAllReferencesInformation: 'getAllReferencesInformation/',
  getAllQueriesInformation: 'getAllQueriesInformation/',
  // ! Endpoints that require an /{id} parameter ~ see below
  getProjectInformation: 'getProjectInformation/',
  getReferenceInformation: 'getReferenceInformation/',
  getQueryInformation: 'getQueryInformation/',
  getProjectComparisons: 'getProjectComparisons/',
  getQueryProjects: 'getQueryProjects/',
  getReferenceProjects: 'getReferenceProjects/',
  getProjectReport: 'getProjectReport/',
  getTargetsTable: 'getTargetsTable/',
  getEfficiencyPlotFromDB: 'getEfficiencyPlotFromDB/',
  getMaintargetsPlotFromDB: 'getMaintargetsPlotFromDB/',
  getOfftargetsPlotFromDB: 'getOfftargetsPlotFromDB/',

  // * POST
  // ! Endpoints that require Body ~ see below
  apoloAuthRegister:'apolo/auth/register',
  saveSelectedRegion: 'saveSelectedRegion/',
  getChimeraSequenceFile: 'getChimeraSequenceFile/',
  createNewProject: 'createNewProject/',
  createNewReference: 'createNewReference/',
  validateQueryDataFile: 'validateQueryDataFile/',
  validateQueryDataTextBox: 'validateQueryDataTextBox/',
  getMaintargetsPlot: 'getMaintargetsPlot/',
  getEfficiencyPlot: 'getEfficiencyPlot/',
  getOfftargetsPlot: 'getOfftargetsPlot/',
  getOfftargetsPlotFromQuery: 'getOfftargetsPlotFromQuery/',
  sendFinishEmail: 'sendFinishEmail/',
  // ! Endpoints that require an /{id} parameter ~ see below
  removeProject: 'removeProject/',
  removeOffTargetComparison: 'removeOffTargetComparison/',
  cancelTask: 'cancelTask/',

  // * PUT
  addNewAnnotationToReference: 'addNewAnnotationToReference/'
};
