/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalState } from '../models/global-state.model';
import { BrowserStorageService } from './browser-storage.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalStateService {
  public globalStateBrowserStorageKey = 'globalState';

  private globalState: GlobalState = {
    accessibilityCheck: false,
    accessibilityWindow: 0,
    currentRoutePath: '',
    endCheck: false,
    endStabilityTreshold: 0,
    missmatches: 0,
    // * method will be dynamic in the future (i.e. via input).
    method: 'siFi21',
    maxGapSize: 0,
    maintargetComparisonID: 0,
    maintargetNumbers: [0],
    offtargetComparisonID: 0,
    taskID: '',
    projectID: 0,
    queryID: 0,
    queryGenus: '',
    querySpecie: '',
    querySequenceName: '',
    querySequence: '',
    querySize: 0,
    queryRegionID: 0,
    queryRegionStart: 0,
    queryRegionEnd: 0,
    referenceIDs: [],
    sirnaSize: 0,
    strandCheck: false,
    terminalCheck: false,
    tsAccessibilityTreshold: 0,
    targetsInRegions: true,
    hasRequestedGetOfftargetsPlotFromQuery: false,
    requestedToAddNewQueryFile: true,
    showModalKeepReference: false
  };

  private globalStateSubject = new BehaviorSubject<GlobalState>({
    ...this.globalState
  });

  constructor(private browserStorageService: BrowserStorageService) {
    const globalStateFromLocalStorage =
      this.browserStorageService.getSessionStorageItem(
        this.globalStateBrowserStorageKey
      );

    if (globalStateFromLocalStorage) {
      this.globalState = JSON.parse(globalStateFromLocalStorage);
      this.globalStateSubject.next({ ...this.globalState });
    }
  }

  public getGlobalState(): Observable<GlobalState> {
    return this.globalStateSubject.asObservable();
  }

  public setGlobalState(newState: Partial<GlobalState>): void {
    const currentState = this.globalStateSubject.getValue();
    const nextState: GlobalState = { ...currentState, ...newState };

    const { showModalKeepReference, ...stateToStore } = nextState;

    this.globalStateSubject.next(nextState);

    this.browserStorageService.setSessionStorageItem(
      this.globalStateBrowserStorageKey,
      JSON.stringify(stateToStore)
    );
  }

  public resetGlobalState(): void {
    this.globalStateSubject.next({
      ...this.globalState
    });

    this.browserStorageService.removeSessionStorageItem(
      this.globalStateBrowserStorageKey
    );

    this.browserStorageService.removeSessionStorageItem('isReload');
  }

  public resetGlobalStateExceptProjectID(): void {
    const currentState = this.globalStateSubject.getValue();

    const newState: GlobalState = {
      accessibilityCheck: false,
      accessibilityWindow: 0,
      currentRoutePath: '',
      endCheck: false,
      endStabilityTreshold: 0,
      missmatches: 0,
      method: 'siFi21',
      maxGapSize: 0,
      maintargetComparisonID: 0,
      maintargetNumbers: [0],
      offtargetComparisonID: 0,
      taskID: '',
      projectID: currentState.projectID,
      queryID: 0,
      queryGenus: '',
      querySpecie: '',
      querySequenceName: '',
      querySequence: '',
      querySize: 0,
      queryRegionID: 0,
      queryRegionStart: 0,
      queryRegionEnd: 0,
      referenceIDs: [],
      sirnaSize: 0,
      strandCheck: false,
      terminalCheck: false,
      tsAccessibilityTreshold: 0,
      targetsInRegions: true,
      hasRequestedGetOfftargetsPlotFromQuery: false,
      requestedToAddNewQueryFile: currentState.requestedToAddNewQueryFile,
      showModalKeepReference: false
    };

    this.globalStateSubject.next(newState);

    this.browserStorageService.setSessionStorageItem(
      this.globalStateBrowserStorageKey,
      JSON.stringify(newState)
    );
  }
}
