/* eslint-disable no-debugger */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../core/services/http.service';
import { environment } from 'src/environments/environment';
import { User } from '../core/models/auth.models';

@Injectable({
  providedIn: 'root'
})
export class RegisterService extends HttpService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(public http: HttpClient) {
    super( http );
    this.url = '/register-postgres-user';
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public parseJwt(token: string): any {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    } catch (e) {
      return null;
    }
  }

  public get currentUserValue(): any {
    const currentUser = this.currentUserSubject.value;
    if (currentUser) {
      const decodedToken = this.parseJwt(currentUser.token);
      return { ...decodedToken, token: currentUser.token };
    }
    return null;
  }

  public getCurrentUserEmail(): string {
    const currentUser = this.currentUserValue;
    return currentUser ? currentUser.email : null;
  }

  public authRegisterObservable( body: any ): Observable<any> {
    return this.http.post( environment.apiUsersUrl + this.url, body, {
      responseType: 'json',
      observe: 'response'
    } );
  }

  public activateUser( code: string, email: string ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      this.activateUserObservable( code, email )
        .subscribe(
          (data) => {
            const response = JSON.parse( JSON.stringify( data ) );
            resolve( response );
          },
          (error) => {
            reject( error );
          }
        );
    } );
  }

  public activateUserObservable( code: string, email: string ): Observable<any> {
    const url = `${ environment.apiUsersUrl + this.url }/activate/${ code }?email=${ email }`;
    return this.http.patch(url, {}, {
      responseType: 'json',
      observe: 'response'
    });
  }

  public recoveryPasswordObservable( email: string ): Observable<any> {
    const url = `${ environment.apiUsersUrl + this.url }/recovery/${ email }`;
    return this.http.get(url, {
      responseType: 'json',
      observe: 'response'
    });
  }

  public resetPasswordObservable( code: string, email: string, password: string ): Observable<any> {
    const url = `${ environment.apiUsersUrl + this.url }/reset-password/${ code }?email=${ email }&password=${ password }`;
    return this.http.patch(url, {}, {
      responseType: 'json',
      observe: 'response'
    });
  }
}
