import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EndpointsService } from './endpoints.service';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {

  constructor(public http: HttpClient, private endpointsService: EndpointsService
  ) { }

  public sendEmail(email: string, taskID: string): Observable<any> {
    const url = this.endpointsService.endpoints.sendFinishEmail;

    const body = new URLSearchParams();
    body.set('email', email);
    body.set('taskID', taskID);

    return this.http.post(url, body.toString(), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      responseType: 'json',
      observe: 'response'
    });
  }
}
