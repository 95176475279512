import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from '../../core/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/core/models/auth.models';

import { GlobalStateService } from 'src/app/services/global-state.service';

@Component( {
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
} )

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;

  constructor( @Inject( DOCUMENT ) private document: any, private router: Router,
    private authService: AuthenticationService,
    private globalStateService: GlobalStateService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
  ) { }

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' }
  ];

  openMobileMenu: boolean;
  user = new User();

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get( 'lang' );
    const val = this.listLang.filter( (x) => x.lang === this.cookieValue );
    this.countryName = val.map( (element) => element.text );
    if ( val.length === 0 ) {
      if ( this.flagvalue === undefined ) {
        this.valueset = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map( (element) => element.flag );
    }

    this.user = this.authService.currentUserValue;
  }

  resetGlobalState() {
    this.globalStateService.resetGlobalState();
  }

  setLanguage( text: string, lang: string, flag: string ) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage( lang );
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu( event: any ) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle( 'fullscreen-enable' );
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement ) {
      if ( this.element.requestFullscreen ) {
        this.element.requestFullscreen();
      } else if ( this.element.mozRequestFullScreen ) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if ( this.element.webkitRequestFullscreen ) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if ( this.element.msRequestFullscreen ) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if ( this.document.exitFullscreen ) {
        this.document.exitFullscreen();
      } else if ( this.document.mozCancelFullScreen ) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if ( this.document.webkitExitFullscreen ) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if ( this.document.msExitFullscreen ) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
}
