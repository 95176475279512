import { Injectable } from '@angular/core';
import { EndpointsService } from './endpoints.service';

@Injectable({
  providedIn: 'root'
})
export class ValidateDataService {
  constructor(private endpointsService: EndpointsService) {}

  /*
   * The reason why we are using the native fetch API instead of Angular's HttpClient is because
   * by using the latest, Angular's error layer does not give us access to the type of error that Apolo's backend
   * is returning, which is why we are using the native fetch API to get the error message (detail).
   *
   * This must be refactor to use Angular's HttpClient as soon as possible.
   */

  public validateQueryDataFile(data: FormData): Promise<any> {
    const validateQueryDataFileEndpoint =
      this.endpointsService.endpoints.validateQueryDataFile;

    return fetch(validateQueryDataFileEndpoint, {
      method: 'POST',
      body: data
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.detail);
          });
        }
        return response.json();
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  public getAlphanumericRegExp(start: number, end: number): RegExp {
    return new RegExp(`^[\\w\\s]{${ start },${ end }}$`);
  }

  public getAlphanumericWithSpecialCharactersRegExp(
    start: number,
    end: number
  ): RegExp {
    return new RegExp(`^[\\w\\s!@#$%^&*(),.?":{}|<>]{${ start },${ end }}$`);
  }
}
