/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, first, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Role } from '../models/permission.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../models/auth.models';

@Injectable( { providedIn: 'root' } )
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  isAdmin = false;
  error: '';
  private jwtHelper: JwtHelperService;

  constructor( private http: HttpClient ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse( localStorage.getItem( 'currentUser' ) )
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.jwtHelper = new JwtHelperService();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
	 * Performs the auth
	 * @param username username of user
	 * @param password password of user
	 */
  login(username: string, password: string): Observable<any> {
    return this.http
      .post<any>(`${ environment.apiUsersUrl }/postgres-users/authenticate`, { username, password })
      .pipe(
        map((response) => {
          const data = JSON.parse(JSON.stringify(response));
          if (data.code === 200) {
            localStorage.setItem('currentUser', JSON.stringify(data.data));
            this.currentUserSubject.next(data.data);
          }
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            return throwError(() => new Error('Unauthorized'));
          } else {
            return throwError(() => new Error('An error occurred'));
          }
        })
      );
  }

  /**
	 * Reset password
	 * @param email email
	 */
  resetPassword( username: string ) {
    return this.http.post( 'User/Reset', {
      username
    } );
  }

  public decodeToken(token: string): any {
    try {
      // Divide el token en sus tres partes (header, payload, signature)
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

      // Decodifica el payload del token
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );

      // Retorna el contenido del payload como objeto JSON
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Invalid token', error);
      return null;
    }
  }

  public getToken(): string {
    return localStorage.getItem( 'token' );
  }

  /**
	 * Logout the user
	 */
  logout() {
    localStorage.removeItem( 'currentUser' );
    this.currentUserSubject.next( null );
    window.location.reload();
  }

  getIsAdmin = () => {
    this.http
      .get( 'User/IsAdmin' )
      .pipe( first() )
      .subscribe(
        (data) => {
          const response = JSON.parse( JSON.stringify( data ) );
          if ( response.result == 0 ) {
            this.isAdmin = response.data;
          } else {
            this.isAdmin = false;
          }
        },
        (error) => {
          this.error = error;
        }
      );
  };

  getPermissions = (): Role[] => {
    const permissions = [];
    this.currentUserValue.userRoles.forEach( (single) => {
      permissions.push( single.role );
    } );

    return permissions;
  };

  getPermissionsName = (): string[] => {
    const permissions = [];
    this.currentUserValue.userRoles.forEach( (single) => {
      permissions.push( single.role.name );
    } );

    return permissions;
  };

  getPermissionsId = (): string[] => {
    const permissions = [];
    this.currentUserValue.userRoles.forEach( (single) => {
      permissions.push( single.roleId );
    } );

    return permissions;
  };

  public isAuthenticated(): boolean {
    const currentUser = this.currentUserValue;
    if ( currentUser && currentUser.token ) {
      // Check whether the token is expired and return
      // true or false
      return !this.jwtHelper.isTokenExpired( currentUser.token );
    }

    return false;
  }

  hasAlmostOnePermission( permissionsRequired: string[] ): boolean {
    let result = false;
    const permissions = this.getPermissionsId();

    permissionsRequired.forEach( (required) => {
      result = result || permissions.includes( required );
    } );
    return result;
  }

  hasAllPermission( permissionsRequired: string[] ): boolean {
    let result = true;
    const permissions = this.getPermissionsId();

    permissionsRequired.forEach( (required) => {
      result = result && permissions.includes( required );
    } );
    return result;
  }
}
