import { Injectable } from '@angular/core';
import { EndpointsService } from './endpoints.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { GlobalStateService } from './global-state.service';

import { GlobalState } from '../models/global-state.model';

@Injectable({
  providedIn: 'root'
})
export class ProcessesService {
  private globalState: GlobalState;
  constructor(
    private http: HttpClient,
    private endpointsService: EndpointsService,
    private globalStateService: GlobalStateService
  ) {
    this.globalStateService.getGlobalState().subscribe((globalState) => {
      this.globalState = globalState;
    });
  }

  public cancelTask(): Observable<any> {
    const cancelProcessEndpoint = this.endpointsService.endpoints.cancelTask;
    const taskID = this.globalState.taskID;

    return this.http.post(`${ cancelProcessEndpoint }${ taskID }`, {}).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getRandomID(startOfId: string): string {
    let randomId: string = startOfId;

    if (
      typeof crypto !== 'undefined' &&
      typeof crypto['randomUUID'] === 'function'
    ) {
      // * This is not available in IE.
      randomId += crypto['randomUUID']();
    } else {
      randomId += Math.random().toString(36).slice(2);
    }

    return randomId;
  }
}
