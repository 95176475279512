<div id="preloader" *ngIf="loading">
  <div id="status">
    <div class="spinner-chase">
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
      <div class="chase-dot"></div>
    </div>
    <div class="container-fluid">
      <p class="loading-message">We are loading everything for you, it will only take a few minutes. Thank you for your patience!</p>
    </div>
  </div>
</div>
