import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';

import { EventService } from '../../core/services/event.service';
import { AuthenticationService } from '../../core/services/auth.service';

import { DOCUMENT } from '@angular/common';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { User } from 'src/app/core/models/auth.models';

@Component( {
  selector: 'app-horizontaltopbar',
  templateUrl: './horizontaltopbar.component.html',
  styleUrls: ['./horizontaltopbar.component.scss']
} )

/**
 * Horizontal Topbar and navbar specified
 */
export class HorizontaltopbarComponent implements OnInit, AfterViewInit {

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;

  menuItems = [];

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' }
  ];

  user = new User();

  // eslint-disable-next-line max-len
  constructor( @Inject( DOCUMENT ) private document: any,
    private router: Router,
    private eventService: EventService,
    private authService: AuthenticationService,
    public languageService: LanguageService,
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    public _cookiesService: CookieService ) {
    router.events.subscribe( (event) => {
      if ( event instanceof NavigationEnd ) {
        this.activateMenu();
      }
    } );
  }

  ngOnInit(): void {
    this.element = document.documentElement;

    this.initialize();

    this.cookieValue = this._cookiesService.get( 'lang' );
    const val = this.listLang.filter( (x) => x.lang === this.cookieValue );
    this.countryName = val.map( (element) => element.text );
    if ( val.length === 0 ) {
      if ( this.flagvalue === undefined ) {
        this.valueset = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map( (element) => element.flag );
    }
    this.user = this.authService.currentUserValue;
  }

  setLanguage( text: string, lang: string, flag: string ) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage( lang );
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
  }

  /**
   * On menu click
   */
  onMenuClick( event ) {
    const nextEl = event.target.nextElementSibling;
    if ( nextEl ) {
      const parentEl = event.target.parentNode;
      if ( parentEl ) {
        parentEl.classList.remove( 'show' );
      }
      nextEl.classList.toggle( 'show' );
    }
    return false;
  }

  ngAfterViewInit() {
    this.activateMenu();
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass( className ) {
    const els = document.getElementsByClassName( className );
    while ( els[0] ) {
      els[0].classList.remove( className );
    }
  }

  /**
   * Togglemenu bar
   */
  toggleMenubar() {
    const element = document.getElementById( 'topnav-menu-content' );
    element.classList.toggle( 'show' );
  }

  /**
   * Activates the menu
   */
  private activateMenu() {

    const resetParent = ( el: any ) => {
      const parent = el.parentElement;
      if ( parent ) {
        parent.classList.remove( 'active' );
        const parent2 = parent.parentElement;
        this._removeAllClass( 'mm-active' );
        this._removeAllClass( 'mm-show' );
        if ( parent2 ) {
          parent2.classList.remove( 'active' );
          const parent3 = parent2.parentElement;
          if ( parent3 ) {
            parent3.classList.remove( 'active' );
            const parent4 = parent3.parentElement;
            if ( parent4 ) {
              parent4.classList.remove( 'active' );
              const parent5 = parent4.parentElement;
              if ( parent5 ) {
                parent5.classList.remove( 'active' );
                const menuelement = document.getElementById( 'topnav-menu-content' );
                if ( menuelement !== null ) {
                  if ( menuelement.classList.contains( 'show' ) ) {
                    document
                      .getElementById( 'topnav-menu-content' )
                      .classList.remove( 'show' );
                  }
                }
              }
            }
          }
        }
      }
    };

    // activate menu item based on location
    const links = document.getElementsByClassName( 'side-nav-link-ref' );
    let matchingMenuItem = null;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for ( let i = 0; i < links.length; i++ ) {
      // reset menu
      resetParent( links[i] );
    }
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for ( let i = 0; i < links.length; i++ ) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      if ( location.pathname === links[i]['pathname'] ) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if ( matchingMenuItem ) {
      const parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if ( parent ) {
        parent.classList.add( 'active' );
        const parent2 = parent.parentElement;
        if ( parent2 ) {
          parent2.classList.add( 'active' );
          const parent3 = parent2.parentElement;
          if ( parent3 ) {
            parent3.classList.add( 'active' );
            const parent4 = parent3.parentElement;
            if ( parent4 ) {
              parent4.classList.add( 'active' );
              const parent5 = parent4.parentElement;
              if ( parent5 ) {
                parent5.classList.add( 'active' );
                const parent6 = parent5.parentElement;
                if ( parent6 ) {
                  parent6.classList.add( 'active' );
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle( 'right-bar-enabled' );
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle( 'fullscreen-enable' );
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement ) {
      if ( this.element.requestFullscreen ) {
        this.element.requestFullscreen();
      } else if ( this.element.mozRequestFullScreen ) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if ( this.element.webkitRequestFullscreen ) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if ( this.element.msRequestFullscreen ) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if ( this.document.exitFullscreen ) {
        this.document.exitFullscreen();
      } else if ( this.document.mozCancelFullScreen ) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if ( this.document.webkitExitFullscreen ) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if ( this.document.msExitFullscreen ) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Initialize
   */
  initialize(): void {
    this.menuItems = MENU;
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems( item: MenuItem ) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

}
