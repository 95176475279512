import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { EndpointsService } from './endpoints.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { GlobalState } from 'src/app/models/global-state.model';

import {
  EfficiencyPlotData,
  EfficiencyPlotFromDBData,
  MainTargetsPlotData,
  MainTargetsPlotFromDBData,
  OffTargetsPlotData,
  OffTargetsPlotFromDBData,
  OffTargetsPlotFromQueryData,
  SaveSelectedRegionBody,
  TargetsTableData
} from '../models/data-plot.model';
import { GlobalStateService } from './global-state.service';
import { ProcessesService } from './processes.service';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root'
})
export class DataPlotService {
  private targetsTableData: TargetsTableData[] = [];

  private mainTargetsPlotData: MainTargetsPlotData = {
    html: '',
    totalTargets: 0,
    maintargetComparisonID: 0
  };

  private efficiencyPlotData: EfficiencyPlotData = {
    html: '',
    totalTargets: 0,
    maintargetComparisonID: 0,
    queryID: 0,
    querySize: 0,
    projectID: 0
  };

  private offTargetsPlotData: OffTargetsPlotData = {
    htmlFileNames: [''],
    projectID: 0,
    queryRegionID: 0,
    tableData: {}
  };

  private offTargetsPlotFromQueryData: OffTargetsPlotFromQueryData = {
    htmlFileNames: [''],
    projectID: 0,
    queryRegionID: 0,
    tableData: {}
  };

  private efficiencyPlotFromDBData: EfficiencyPlotFromDBData = {
    html: '',
    totalTargets: 0,
    maintargetComparisonID: 0,
    queryID: 0,
    querySize: 0,
    projectID: 0
  };

  private mainTargetsPlotDataFromDBData: MainTargetsPlotFromDBData = {
    html: '',
    totalTargets: 0,
    maintargetComparisonID: 0
  };

  private offTargetsPlotFromDBData: OffTargetsPlotFromDBData = {
    html: '',
    projectID: 0,
    queryRegionID: 0,
    tableData: {}
  };

  private targetsTableDataSubject = new BehaviorSubject<TargetsTableData[]>(
    this.targetsTableData
  );

  private mainTargetsPlotDataSubject = new BehaviorSubject<MainTargetsPlotData>(
    this.mainTargetsPlotData
  );

  private efficiencyPlotDataSubject = new BehaviorSubject<EfficiencyPlotData>(
    this.efficiencyPlotData
  );

  private offTargetsPlotDataSubject = new BehaviorSubject<OffTargetsPlotData>(
    this.offTargetsPlotData
  );

  private mainTargetsPlotDataFromDBDataSubject =
    new BehaviorSubject<MainTargetsPlotFromDBData>(
      this.mainTargetsPlotDataFromDBData
    );

  private offTargetsPlotFromQueryDataSubject =
    new BehaviorSubject<OffTargetsPlotFromQueryData>(
      this.offTargetsPlotFromQueryData
    );

  private efficiencyPlotFromDBDataSubject =
    new BehaviorSubject<EfficiencyPlotFromDBData>(
      this.efficiencyPlotFromDBData
    );

  private offTargetsPlotFromDBDataSubject =
    new BehaviorSubject<OffTargetsPlotFromDBData>(
      this.offTargetsPlotFromDBData
    );

  private modalService: ModalService;

  constructor(
    private http: HttpClient,
    private endpointsService: EndpointsService,
    private globalStateService: GlobalStateService,
    private processesService: ProcessesService,
    private injector: Injector
  ) {
    setTimeout(() => {
      this.modalService = this.injector.get(ModalService);
    });
  }

  /**
   ** Every if() statement within the 'tap' operator is
   ** to ensure that the data is not 'null' before setting
   ** the data to the respective BehaviorSubject.
   ** This is because, the server will return an object
   ** with the matching keys (see specific model), but with 'null'
   ** values, only when the 'ID' sent to the server is not found
   ** in the database.
   */

  public fetchAndSetTargetsTableData(
    mainTargetComparisonID: number
  ): Observable<any> {
    const getTargetsTableDataEndpoint =
      this.endpointsService.endpoints.getTargetsTable;

    return this.http
      .get(`${ getTargetsTableDataEndpoint }${ mainTargetComparisonID }`)
      .pipe(
        tap((data: TargetsTableData) => {
        // * Ensure data is an array to handle unexpected types (e.g., error objects).
          if (Array.isArray(data) && data.length) {
            this.setTargetsTableData = data;
          }
        }),
        catchError((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching targets table data:', error);
          return throwError(error);
        })
      );
  }

  public fetchAndSetMainTargetsPlotData(data: GlobalState): Observable<any> {
    const getMainTargetsPlotEndpoint =
      this.endpointsService.endpoints.getMaintargetsPlot;

    const taskID = this.processesService.getRandomID('taskID-');
    this.globalStateService.setGlobalState({ taskID });

    return this.http.post(getMainTargetsPlotEndpoint, { ...data, taskID }).pipe(
      tap((data: MainTargetsPlotData) => {
        if (data.html) {
          this.setMainTargetsPlotData = data;
        }

        this.globalStateService.setGlobalState({
          taskID: ''
        });
      }),
      catchError((error) => {
        if (error && error.includes('Internal Server Error')) {
          this.modalService.showModalErrorResponse();
        }
        return throwError(error);
      })
    );
  }

  public fetchAndSetEfficiencyPlotData(data: GlobalState): Observable<any> {
    const getEfficiencyPlotEndpoint =
      this.endpointsService.endpoints.getEfficiencyPlot;

    return this.http.post(getEfficiencyPlotEndpoint, data).pipe(
      tap((data: EfficiencyPlotData) => {
        if (data.html) {
          this.setEfficiencyPlotData = data;
        }
      }),
      catchError((error) => {
        if (error && error.includes('Internal Server Error')) {
          this.modalService.showModalErrorResponse();
        }
        return throwError(error);
      })
    );
  }

  public fetchAndSetOffTargetsPlotData(data: GlobalState): Observable<any> {
    const getOffTargetsPlotEndpoint =
      this.endpointsService.endpoints.getOfftargetsPlot;

    const taskID = this.processesService.getRandomID('taskID-');
    this.globalStateService.setGlobalState({ taskID });

    return this.http.post(getOffTargetsPlotEndpoint, { ...data, taskID }).pipe(
      tap((data: OffTargetsPlotData) => {
        if (data.htmlFileNames) {
          this.setOffTargetsPlotData = data;
        }

        this.globalStateService.setGlobalState({
          taskID: ''
        });
      }),
      catchError((error) => {
        if (error && error.includes('Internal Server Error')) {
          this.modalService.showModalErrorResponse();
        }
        return throwError(error);
      })
    );
  }

  public fetchAndSetOffTargetsPlotFromQueryData(
    data: GlobalState
  ): Observable<any> {
    const getOffTargetsPlotFromQueryEndpoint =
      this.endpointsService.endpoints.getOfftargetsPlotFromQuery;

    const taskID = this.processesService.getRandomID('taskID-');
    this.globalStateService.setGlobalState({ taskID });

    return this.http
      .post(getOffTargetsPlotFromQueryEndpoint, { ...data, taskID })
      .pipe(
        tap((data: OffTargetsPlotFromQueryData) => {
          if (data.htmlFileNames) {
            this.setOffTargetsPlotFromQueryData = data;
          }

          this.globalStateService.setGlobalState({
            taskID: ''
          });
        }),
        catchError((error) => {
          if (error && error.includes('Internal Server Error')) {
            this.modalService.showModalErrorResponse();
          }
          return throwError(error);
        })
      );
  }

  public fetchAndSetEfficiencyPlotFromDBData(
    mainTargetComparisonID: number
  ): Observable<any> {
    const getEfficiencyPlotFromDBEndpoint =
      this.endpointsService.endpoints.getEfficiencyPlotFromDB;

    return this.http
      .get(`${ getEfficiencyPlotFromDBEndpoint }${ mainTargetComparisonID }`)
      .pipe(
        tap((data: EfficiencyPlotFromDBData) => {
          if (data.html) {
            this.setEfficiencyPlotFromDBData = data;
          }
        }),
        catchError((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching efficiency plot from DB:', error);
          return throwError(error);
        })
      );
  }

  public fetchAndSetMainTargetsPlotFromDBData(
    mainTargetComparisonID: number
  ): Observable<any> {
    const getMainTargetsPlotFromDBEndpoint =
      this.endpointsService.endpoints.getMaintargetsPlotFromDB;

    return this.http
      .get(`${ getMainTargetsPlotFromDBEndpoint }${ mainTargetComparisonID }`)
      .pipe(
        tap((data: MainTargetsPlotFromDBData) => {
          if (data.html) {
            this.setMainTargetsPlotDataFromDBData = data;
          }
        }),
        catchError((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching main targets plot from DB:', error);
          return throwError(error);
        })
      );
  }

  public fetchAndSetOffTargetsPlotFromDBData(
    offTargetComparisonID: number
  ): Observable<any> {
    const getOffTargetsPlotFromDBEndpoint =
      this.endpointsService.endpoints.getOfftargetsPlotFromDB;

    return this.http
      .get(`${ getOffTargetsPlotFromDBEndpoint }${ offTargetComparisonID }`)
      .pipe(
        tap((data: OffTargetsPlotFromDBData) => {
          if (data.html) {
            this.setOffTargetsPlotFromDBData = data;
          }
        }),
        catchError((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching off targets plot from DB:', error);
          return throwError(error);
        })
      );
  }

  public saveSelectedRegion(body: SaveSelectedRegionBody): Observable<any> {
    const saveSelectedRegionEndpoint =
      this.endpointsService.endpoints.saveSelectedRegion;

    return this.http.post(saveSelectedRegionEndpoint, body).pipe(
      catchError((error) => {
        // eslint-disable-next-line no-console
        console.error('Error saving selected region:', error);
        return throwError(error);
      })
    );
  }

  // * Getters

  public get getTargetsTableData$(): Observable<TargetsTableData[]> {
    return this.targetsTableDataSubject.asObservable();
  }

  public get getMainTargetsPlotData$(): Observable<MainTargetsPlotData> {
    return this.mainTargetsPlotDataSubject.asObservable();
  }

  public get getEfficiencyPlotData$(): Observable<EfficiencyPlotData> {
    return this.efficiencyPlotDataSubject.asObservable();
  }

  public get getOffTargetsPlotData$(): Observable<OffTargetsPlotData> {
    return this.offTargetsPlotDataSubject.asObservable();
  }

  public get getOffTargetsPlotFromQueryData$(): Observable<OffTargetsPlotFromQueryData> {
    return this.offTargetsPlotFromQueryDataSubject.asObservable();
  }

  public get getEfficiencyPlotFromDBData$(): Observable<EfficiencyPlotFromDBData> {
    return this.efficiencyPlotFromDBDataSubject.asObservable();
  }

  public get getMainTargetsPlotFromDBData$(): Observable<MainTargetsPlotFromDBData> {
    return this.mainTargetsPlotDataFromDBDataSubject.asObservable();
  }

  public get getOffTargetsPlotFromDBData$(): Observable<OffTargetsPlotFromDBData> {
    return this.offTargetsPlotFromDBDataSubject.asObservable();
  }

  // * Setters

  private set setTargetsTableData(targetsTableData: TargetsTableData[]) {
    this.targetsTableDataSubject.next(targetsTableData);
  }

  private set setMainTargetsPlotData(mainTargetsPlotData: MainTargetsPlotData) {
    this.mainTargetsPlotDataSubject.next(mainTargetsPlotData);
  }

  private set setEfficiencyPlotData(efficiencyPlotData: EfficiencyPlotData) {
    this.efficiencyPlotDataSubject.next(efficiencyPlotData);
  }

  private set setOffTargetsPlotData(offTargetsPlotData: OffTargetsPlotData) {
    this.offTargetsPlotDataSubject.next(offTargetsPlotData);
  }

  private set setOffTargetsPlotFromQueryData(
    offTargetsPlotFromQueryData: OffTargetsPlotFromQueryData
  ) {
    this.offTargetsPlotFromQueryDataSubject.next(offTargetsPlotFromQueryData);
  }

  private set setEfficiencyPlotFromDBData(
    efficiencyPlotFromDBData: EfficiencyPlotFromDBData
  ) {
    this.efficiencyPlotFromDBDataSubject.next(efficiencyPlotFromDBData);
  }

  private set setMainTargetsPlotDataFromDBData(
    mainTargetsPlotDataFromDBData: MainTargetsPlotFromDBData
  ) {
    this.mainTargetsPlotDataFromDBDataSubject.next(
      mainTargetsPlotDataFromDBData
    );
  }

  private set setOffTargetsPlotFromDBData(
    offTargetsPlotFromDBData: OffTargetsPlotFromDBData
  ) {
    this.offTargetsPlotFromDBDataSubject.next(offTargetsPlotFromDBData);
  }
}
