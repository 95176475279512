import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 10,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true,
    authRoles: ['all']
  },
  {
    id: 20,
    label: 'MENUITEMS.DASHBOARDS.TEXT',
    icon: 'bx-home-circle',
    link: '/',
    authRoles: ['all']
  },
  {
    id: 30,
    label: 'MENUITEMS.ORGANIZATIONS.TEXT',
    icon: 'bx-grid-alt',
    link: '/organizaciones',
    authRoles: ['organizador']
  },
  {
    id: 40,
    label: 'MENUITEMS.USERS.TEXT',
    icon: 'bx-user',
    link: '/users',
    authRoles: ['admin']
  },
  {
    id: 50,
    label: 'MENUITEMS.MEMBERS.TEXT',
    icon: 'bx-user-circle',
    link: '/miembros',
    authRoles: ['loaner']
  },
  {
    id: 60,
    label: 'MENUITEMS.CLIENTS.TEXT',
    icon: 'bxs-user-detail',
    link: '/clientes',
    authRoles: ['loaner']
  },
  {
    id: 70,
    label: 'MENUITEMS.LOANS.LIST.LOANS',
    icon: 'bx-dollar-circle',
    link: '/creditos',
    authRoles: ['loaner']
  },
  {
    id: 80,
    label: 'MENUITEMS.LOANS.LIST.APPLICATIONS',
    icon: 'bx-paste',
    link: '/solicitudes',
    authRoles: ['loaner']
  },
  {
    id: 80,
    label: 'MENUITEMS.LOANS.LIST.PIPELINES',
    icon: 'bx-sitemap',
    link: '/pipelines',
    authRoles: ['loaner']
  }
  // {
  //     id: 80,
  //     label: 'MENUITEMS.CALENDAR.TEXT',
  //     icon: 'bx-calendar',
  //     link: '/calendario',
  // },
  // {
  //     id: 90,
  //     label: 'MENUITEMS.SETTINGS.TEXT',
  //     isTitle: true
  // },
  // {
  //     id: 100,
  //     label: 'MENUITEMS.SETTINGS.LIST.PROFILE',
  //     icon: 'bx-slider-alt',
  //     link: '/cuenta/perfil',
  // },
  // {
  //     id: 110,
  //     label: 'MENUITEMS.SETTINGS.LIST.SECURITY',
  //     icon: 'bx-shield-quarter',
  //     link: '/cuenta/seguridad',
  // },
];

