import { UserRole } from './user-permission.model';

export class User {
  id: string;
  isActive?: boolean;
  password: string;
  firstName?: string;
  lastName?: string;
  token?: string;
  email: string;
  active: boolean;
  roles?: string[];
  role?: string[];

  public userRoles: UserRole[];


  constructor() {
    this.userRoles = [];
  }
}
