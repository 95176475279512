import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NewProject } from '../models/projects.model';

import { ValidateDataService } from 'src/app/services/validate-data.service';
import { EndpointsService } from './endpoints.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  private apiEndpoints = {
    get: this.endpointsService.endpoints.getProjectInformation,
    create: this.endpointsService.endpoints.createNewProject,
    remove: this.endpointsService.endpoints.removeProject
  };

  constructor(
    private http: HttpClient,
    private validateDataService: ValidateDataService,
    private endpointsService: EndpointsService
  ) {}

  public initNewProjectForm(): FormGroup {
    const regExpPosition = {
      start: 1,
      end: 50
    };

    return new FormBuilder().group({
      projectName: [
        '',
        [
          Validators.required,
          Validators.pattern(
            this.validateDataService.getAlphanumericWithSpecialCharactersRegExp(
              regExpPosition.start,
              regExpPosition.end
            )
          )
        ]
      ],
      projectDescription: [
        '',
        [
          Validators.required
        ]
      ],
      genus: [
        '',
        [
          Validators.required,
          Validators.pattern(
            this.validateDataService.getAlphanumericRegExp(
              regExpPosition.start,
              regExpPosition.end
            )
          )
        ]
      ],
      specie: [
        '',
        [
          Validators.required,
          Validators.pattern(
            this.validateDataService.getAlphanumericRegExp(
              regExpPosition.start,
              regExpPosition.end
            )
          )
        ]
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            this.validateDataService.getAlphanumericWithSpecialCharactersRegExp(
              regExpPosition.start,
              regExpPosition.end
            )
          )
        ]
      ]
    });
  }

  public handleProjectRequest(
    bodyData?: NewProject,
    method?: 'get' | 'create' | 'remove',
    projectId?: number
  ): Observable<any> {
    const fullApiEndpoint: string = this.apiEndpoints[method];

    const request$ = this.createRequest(
      fullApiEndpoint,
      method,
      bodyData,
      projectId
    );

    return request$.pipe(
      catchError((error: Error) => {
        // eslint-disable-next-line no-console
        console.error('Error: -ProjectsService.projectService:', error);

        return of({ data: null, error: true });
      })
    );
  }

  private createRequest(
    url: string,
    method: 'get' | 'create' | 'remove',
    bodyData?: NewProject,
    projectId?: number
  ): Observable<any> {
    switch (method) {
      case 'get':
        return this.http.get<any>(`${ url }${ projectId }`);
      case 'create':
        return this.http.post<any>(url, bodyData);
      case 'remove':
        return this.http.post<any>(`${ url }${ projectId }`, {});
      default:
        throw new Error(`Unsupported method: ${ method }`);
    }
  }
}
